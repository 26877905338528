import { Link } from 'react-router-dom'
import './styles.scss'

function Terms() {
  return (
    <div className='terms__wrapper'>
      <div className='terms__content'>
        <h1>Terms & Conditions</h1>
        <p>
          By completing any transaction with HYVE or using a HYVE service in any
          format, you are agreeing to abide by the Terms & Conditions. In
          addition to this, you are also agreeing to our other policies that
          reside in the Terms & Condition but also form their own entity.
          <p>
            <Link to='/rules'>House Rules</Link>
          </p>
          <p>
            <Link>Cancellation Policy</Link>
          </p>
          <p>
            <Link>Privacy Policy</Link>
          </p>
        </p>
        <p>
          These Terms & Conditions (“Terms”) constitute a legally binding
          agreement between you and J LOAD INC LIMITED, trading as HYVE (“HYVE”,
          “we”, “us” or “our”). These Terms govern your access to the use of the
          HYVE gyms and HYVE private studios, the (“Network”), the HYVE Website
          and any other websites through which HYVE makes the Network available,
          the (“Website”) and our Mobile Application, other smart device
          applications and application program interfaces, the (“APIs”) through
          which HYVE makes the Network available, the (“Mobile Application”).
          The Website, the Mobile Application and Network are collectively
          referred to as (“HYVE”).{' '}
        </p>
        <h4>1.General</h4>
        <p>
          HYVE is a trading name of J LOAD INC LIMITED company registered in
          England and Wales. Our company registration number is 12332350. If you
          have any questions or if you have any complaints after reading these
          Terms, please contact us. You can contact us on hi@trainhyve.com. If
          we take no action on any breach of this agreement or give you extra
          time to pay or comply, it will not stop us enforcing the Terms again
          strictly at a future date. We may assign the benefit of these Terms
          and our rights thereunder to a third-party on notice to you. Your
          rights under these Terms will not be prejudiced. We will not be liable
          or responsible for any failure to perform, or delay in performance of,
          any of our obligations under these Terms that is caused by any event
          that is outside of our reasonable control. We will not be liable or
          responsible for outstanding money paid to a Personal Trainer. HYVE
          does not arrange or interfere with the relationship between the
          Personal Trainer and Client. As a consumer, you have legal rights in
          relation to any services that are not carried out with reasonable
          skill and care, or if the materials we use are faulty or not as
          described. Advice about your legal rights is available from your local
          Citizens' Advice Bureau or Trading Standards office. Nothing in these
          Terms will affect these legal rights. We may terminate this agreement
          with immediate effect on notifying you if you are in breach of this or
          any other of the policies that reside below. HYVE reserves the right
          to change or modify any of these Terms without notice and in its sole
          discretion. Any changes or modifications to these Terms will be
          effective upon posting of the changes. Your continued use of HYVE
          following the posting of any changes or modifications constitutes your
          acceptance of such changes or modifications and if you do not agree
          with these changes or modifications, you must immediately cease using
          HYVE. You are responsible for regularly reviewing the most current
          Terms as well as all other policies that reside in the below.
        </p>
        <h4>2. ELIGIBILITY AND USE OF HYVE</h4>
        <p>
          You must be 16 years old or over and able to enter legally binding
          contracts to access and use HYVE or register for a HYVE Account. HYVE
          (which constitutes the Website, Mobile Application and Network) are
          not available to persons under the age of 16. By using the Website,
          Mobile Application or Network, you represent and warrant that you are
          16 years old or over and have the legal capacity and authority to
          enter a contract. Furthermore, you will not allow the access of
          anybody under 16 years old into HYVE without prior approval from HYVE.
          Please email hi@trainhyve.com for approval. Your participation in
          using HYVE is for your sole, personal use and not for commercial
          purposes. You may not authorise others to use your username and/or
          password in any entity. When using HYVE, you agree to comply with all
          applicable laws of the country, state, province or city in which you
          are present whilst using HYVE. As described in the Privacy Policy
          which can be found in the headline of these Terms, HYVE uses a
          third-party payment processor (“Stripe”) to link your credit card
          Account to the Website and the Mobile Application. The processing of
          your payments or Credits for the Website, Mobile Application or
          Network will be subject to the Terms & Conditions and Privacy Policy
          of Stripe along with the issuing bank in addition to these Terms. HYVE
          is not responsible for any errors made by Stripe or your issuing bank.
          By using HYVE, you agree to be bound by and comply with any additional
          Term & Conditions and other residing policies provided by the Landlord
          and/or the Managing Agent (“Property Managers”) of the Network
          including compliance with building security procedures, IT access and
          procedures provided by the Property Managers which may be provided in
          electronic format through the Website, Mobile Application or in
          hardcopy in the Network. Some components of HYVE implement APIs such
          as Google Maps. Your use of Google Maps is subject to the Google Maps
          Terms & Conditions and if you visit the Website or download the Mobile
          Application from the Apple App Store, you are also agreeing to Apple’s
          Terms & Conditions. You can find both sets of Terms & Conditions of
          each respective Website.
        </p>
        <h4>3. PERSONAL TRAINER SPECIFIC TERMS</h4>
        <p>
          If you are a personal trainer using HYVE, you must have a valid
          Professional Liability Insurance Policy with a minimum general
          liability coverage of £2,000,000 in order to sign up for a personal
          trainer Account and to use the Network. This insurance policy must be
          in force at the time to register for an Account and must be kept in
          force during your use of HYVE. We reserve the right to request a copy
          of an insurance policy and any other information we may require.{' '}
        </p>
        <h4>4. ACCOUNT REGISTRATION</h4>
        <p>
          To be able to use the Mobile/Website Application or the Network you
          need to sign up for an Account with HYVE which you can do through the
          Web Application. When signing up, you will be asked to provide HYVE
          with your personal information and credit card information which will
          be held and processed by our third-party payment processor, Stripe, as
          set out above and in our Privacy Policy. Once you complete your
          sign-up with HYVE, you will be provided with a personal account
          (“Account”). You agree to complete and maintain accurate and
          up-to-date information in your Account. Your failure to complete and
          maintain accurate and up-to-date Account information, including having
          an invalid or expired credit card on file, may result in your
          inability to access and use the Website, Mobile Application or
          Network. HYVE reserves the right to suspend or terminate your Account
          and refuse all current or future uses of HYVE if HYVE has reasonable
          grounds to suspect that such information is inaccurate, outdated or
          untrue. You may not use HYVE for any illegal purpose or in any manner
          inconsistent with these Terms. You are responsible for all activity
          that occurs with respect to your Account including, but not limited
          to, use of the Website, Mobile Application and Network. Without
          limiting the foregoing, for any reservation made through the Account,
          if other persons are present in the Network, you hereby agree to be
          fully responsible and to indemnify HYVE for any violation of these
          Terms or applicable laws, by-laws or regulations, even if such
          violation was caused by such other persons
        </p>
        <h4>5. HOUSE RULES</h4>
        <div className='rules__body'>
          <p>Please read the below carefully. </p>
          <p>
            By completing any transaction with HYVE or using a HYVE service in
            any format, you are agreeing to abide by the House Rules. We ask you
            to make sure you are comfortable that you can comply with the House
            Rules before continuing. This is not an exhaustive list and HYVE has
            the right to use its sole discretion to add or alter at any time to
            prevent a breach of our <Link>Terms & Conditions.</Link>
          </p>
          <h4>You will</h4>
          <ul>
            <li>All guests must be .</li>
            <li>
              You will wear suitable gym clothing and footwear whilst using any
              HYVE facility.
            </li>
            <li>You will sanitise your hands before using HYVE equipment.</li>
            <li>
              You will safely store any bags and personal belongings off the gym
              floor and inside the lobby or bathroom areas.
            </li>
            <li>
              You will leave the HYVE studio or gym in a clean and tidy
              condition. This includes but not limited to, putting away
              equipment neatly, removing any trip hazards, disposing of any
              litter, wiping down machines and equipment, clearing any spillages
              and sanitising your hands.
            </li>
            <li>
              You will take all your belongings with you when leaving HYVE.
              Neither HYVE, the Landlord and/or the Managing Agent is
              responsible for any property left behind.
            </li>
            <li>
              You will exit the HYVE studio on time and be courteous to any
              users entering after you.
            </li>
            <li>
              You will be responsible and charged for the damage to equipment
              outside of normal wear and tear or necessary cleaning of a HYVE
              studio due to your violation of this agreement or any agreement as
              outlined in the <Link>Terms & Conditions.</Link>
            </li>
            <li>
              You will be held liable and charged for any costs incurred to
              replace equipment taken from a HYVE facility.
            </li>
            <li>
              You will provide proof of identity if reasonably requested by
              HYVE, the Landlord and/or Managing Agent.
            </li>
            <li>
              You will comply with any additional rules, regulations and
              requests communicated through the HYVE Website, Mobile
              Application, Network or otherwise.
            </li>
          </ul>

          <h4>YOU WILL NOT</h4>
          <ul>
            <li>
              You will not allow access to more people than the advertised
              capacity of each HYVE studio, inclusive of yourself
            </li>
            <li>
              You will not share any PIN number or Access Device supplied by
              HYVE to access the facility
            </li>
            <li>
              You will not use the Website, Mobile Application or Network for
              sending or storing any unlawful material.
            </li>
            <li>You will not use HYVE for unlawful or illegal purposes.</li>
            <li>
              You will not use the HYVE Website, Mobile Application or Network
              for any inappropriate purposes, including but not limited to
              drugs, alcohol, gambling, sexual activity, extreme sports or any
              other purpose likely to reflect negatively on HYVE, the Landlord
              and/or the Managing Agent.
            </li>
            <li>
              You will not smoke inside any HYVE facility (this includes
              e-cigarettes).
            </li>
            <li>
              You will not bring any harmful weapons or sharp objectives into
              the HYVE facility including but not limited to firearms and
              knives.
            </li>
            <li>
              You will not allow any naked flame inside HYVE through lighters,
              candles or other.
            </li>
            <li>
              You will not make excessive noise or create noise that HYVE, the
              Landlord and/or Managing Agent deems to be a disturbance to
              surrounding retail, office or residential dwellings.
            </li>
            <li>
              You will not bring any animals into a HYVE gym or Studio, except
              for service animals which should always be harnessed and attached
              to a lead.
            </li>
            <li>
              You will not allow the access of anybody under 16 years old into a
              HYVE studio or gym facility.
            </li>
            <li>
              You will not install, remove or modify any HYVE fixtures,
              fittings, equipment, hardware or appliances.
            </li>
            <li>
              You will not copy or distribute the Website, Mobile Application,
              Network or any other content without written permission from HYVE
              for the use of the Intellectual Property.
            </li>
            <li>
              You will not use the WiFi or Bluetooth services of HYVE for
              unlawful purposes or anti-social behaviour.
            </li>
            <li>
              You will not tarnish or purposely sabotage the HYVE brand or
              intellectual property through the Website, Mobile Application or
              Network.
            </li>
          </ul>
          <h4>ACCESS ABUSE POLICY</h4>
          <p>
            Any PIN number / Access Device assigned to you can only be used by
            you and your PIN number / Access Device is issued solely for your
            use, as your Account is personal to you and only covers your use of
            a HYVE facility. You are responsible for keeping your PIN number /
            Access Device secure and confidential at all times. The PIN number /
            Access Device remains our property at all times (unless agreed
            otherwise and evidenced in writing).
          </p>

          <p>
            Use of any issued PIN numbers is monitored in the interests of the
            safety and security of all our users, use of PIN numbers and access
            is monitored and individuals using PIN numbers / Access Devices may
            be asked to provide proof of identification.
          </p>

          <p>
            Should we believe that your PIN number / Access Device has been used
            by another individual or individuals we may (in our discretion)
            decide to conduct an investigation. If we do so we will: (a) inform
            you, via email, that we believe your PIN number / Access Device has
            been used by another individual or individuals and ask you to
            provide us with reasonable assistance to investigate the matter; and
            (b) following our investigation we will contact you, via email, to
            inform you of our findings and our proposed course of action, which
            may include one or more of the steps set out in the below.
          </p>

          <p>
            If you unreasonably refuse to cooperate with our investigation, or
            following our investigation we have reasonable grounds to believe
            that your PIN number / Access Device was used, with or without your
            knowledge and/or consent, by another individual or individuals,
            depending on the particular circumstances of each case, we reserve
            the right to take one or both of the following steps, which are in
            addition to any other legal rights that we may have: (a) to apply a
            penalty charge to your card on file. The penalty charge will be
            calculated as being equal to the daily charge (that applied at the
            time of use) for each occasion on which your PIN number was used by
            that individual/those individuals; and/or (b) in the event of
            serious misuse of your PIN number, for example, your PIN number has
            been used on repeated occasions and/or by more than one individual,
            to notify you, via email, that we are cancelling your Account with
            immediate effect, and no refunds will be given.
          </p>

          <p>
            If we have reasonable grounds for believing that you knowingly
            provided your PIN number / Access Device to another individual or
            individuals, or allowed unauthorised entry following your entry to
            the HYVE facility (known as tailgating) in addition to our rights
            referred to above, we may hold you responsible for the conduct of
            the individual(s) while on our premises, and liable for any loss we
            suffer as a consequence of that conduct.
          </p>

          <p>
            (“Access Device”) is the device, key-fob, or any other relevant
            security hardware or software device with built-in authentication
            equipment, issued or otherwise provided to you by us to enable you
            to securely access the relevant HYVE facility in accordance with the
            Terms of your Account. Only one device can be registered to an
            Account at any time, if you lose or misplace the device you need to
            contact hi@trainhyve.com to remove the device immediately.
          </p>
        </div>
        <h4>6. DAMAGES, REPAIR AND/OR CLEANING FEES</h4>
        <p>
          As a user of HYVE, you are responsible for leaving the Network, any
          property and surrounding area in the same or better condition than
          when you arrived. You are responsible for your actions and omissions
          and are also responsible for the acts and omissions of any individuals
          whom you invite and/or provide access to. Further to this, you are
          responsible for the cost of any damage repairs or necessary cleaning
          of the Network resulting from your violation of this agreement or your
          use of the Network in excess of normal “wear and tear”. If HYVE, in
          its reasonable discretion, determines that excessive repair or
          cleaning is required, HYVE reserves the right to charge the payment
          method designated in your Account for the reasonable cost of such
          repair and/or cleaning as well as an additional service fee which
          shall not exceed £500 per occurrence. Any such amounts are
          non-refundable and at the reasonable discretion of HYVE.
        </p>
        <h4>7. PAYMENT TERMS</h4>
        <p>
          Any fees which HYVE may charge you for the use of the Website, Mobile
          Application or Network will be as set out in the Website and/or Mobile
          application at the time of the purchase. These fees are inclusive of
          any taxes payable e.g. VAT. HYVE offers no Cash Refund Policy, instead
          we work in line with our Cancellation Policy which can be found in the
          headline of these Terms and below Clause 9. This no Refund Policy
          shall always apply regardless of your decision to terminate your
          usage. HYVE reserves the right to determine final prevailing pricing.
          You do hereby agree that you shall be charged fees based on the length
          of time of your booking of the HYVE, regardless if you only make use
          of the HYVE for less than your booked time. If you exceed the amount
          of time for which you booked a HYVE, then you shall be charged for
          such excess time, as well as an overage charge if such excess time
          conflicts with another users booked time. You do hereby consent to
          such excess time charges and to such overage charge. HYVE may make
          promotional offers to any of our customers. These promotional offers,
          unless made to you, shall have no bearing whatsoever on your offer or
          contract. HYVE may change the fees for the Website, Mobile Application
          or Network at our sole discretion. We encourage you to check back at
          our Website and/or Mobile Application periodically to find out about
          how we charge for the Website, Mobile Application and Network. You can
          find out more information below Clause 21. If a credit card charge is
          declined, we will notify you to provide a valid replacement. Failure
          to provide a replacement within 48 hours may result in the suspension
          of your rights from accessing the Network. If a payment is declined,
          refunded, cancelled or charged back by your issuing bank, or another
          person, you are not entitled to the return of any associated service
          fees, such as payment processing fees, irrespective of the reason for
          such decline, refund, cancellation or charge back. HYVE reserves the
          right with sole discretion to review and refuse a payment or the
          processing of a payment if it suspects any fraudulent activity or any
          other reason without limit. If you access the Website via your mobile
          e.g. through the Mobile Application, please be aware that your
          carrier’s normal rates will still apply.
        </p>
        <h4>8. CANCELLATION POLICY</h4>
        <h4>CANCELLATIONS BY YOU</h4>
        <p>
          All cancellations must take place on the HYVE Website Application. You
          can cancel your session via the ‘MY ACCOUNT section in the menu,
          subject to the below policy. Due to the nature of our business model,
          we require a cancellation procedure to be in place to maintain a fair
          system and prevent empty bookings due to late cancellations. Dependent
          on what method you use to book your session will depend on the
          Cancellation Policy that is applied. Upon cancellation, any remaining
          credits in the account will be forfeited and removed effective
          immediately on the cancellation date.
        </p>
        <h4>CLASSES</h4>
        <p>
          Classes must be cancelled at least 6 hours prior to the scheduled
          start time. Failure to cancel within this timeframe, or failure to
          attend without notice, may result in a temporary suspension of booking
          privileges for future classes.
        </p>
        <h4>PAUSING MEMBERSHIPS</h4>
        <p>
          Memberships may be paused for a maximum period of one month. For any
          pause period exceeding one month, the membership must be canceled and
          may be reactivated when you are ready to rejoin.
        </p>
        <h4>Credits</h4>
        <p>
          If you’ve paid using Credits, you will have 48 hours before your
          session to cancel. Your Credits will be returned immediately to your
          Account if these Terms are satisfied. If these Terms are not
          satisfied, Credits will be taken, and no refund will be given.
        </p>
        <h4>Pay-As-You-Go</h4>
        <p>
          If you’ve paid using pay-as-you-go, you will have 48 hours before your
          session to cancel. We will not charge your card if these Terms are
          satisfied. If these Terms are not satisfied, payment will be taken,
          and no refund will be given.
        </p>
        <h4>CANCELLATIONS BY US</h4>
        <p>
          We may cancel your session subject to a force majeure or similar
          making it impossible or significantly impede us to carry out the
          service. We will always endeavour to let you know as early as possible
          via email and process any cancellation refunds as necessary.
        </p>
        <h4>10. LICENSING, RESTRICTIONS AND COPYRIGHT</h4>
        <p>
          Subject to your compliance with these Terms, HYVE grants you a
          limited, non-exclusive, non-transferable and revocable license to use
          HYVE. Should you choose to download content from HYVE, you must do so
          in accordance with these Terms. Such content is provided to you for
          its intended purposes only and always remains the property of HYVE.
          You will not use, copy, adapt, decompile, modify, reverse engineer,
          prepare, derivative works from, distribute, license, sell, transfer,
          publicly display, publicly perform, transmit, stream, broadcast or
          otherwise exploit the Website, Mobile Application, Network or any
          content, except as expressly permitted in these Terms. No licenses or
          rights are granted to you by implication or otherwise under any
          intellectual property rights owned or controlled by HYVE or its
          licensors, except for the licenses and rights expressly granted in
          these Terms or expressly agreed in writing by HYVE.
        </p>
        <h4>11. USER CONTENT</h4>
        <p>
          HYVE may, in its sole discretion, permit users of the Network to post,
          upload, publish, submit or transmit content. You are solely
          responsible for all content that you upload, email, post or otherwise
          transmit including documents, text, graphics, video, messages, forum
          postings, your profile information, comments, questions, other
          materials (“User Content”). By making available any User Content on or
          through the Website, Mobile Application and/or Network, you hereby
          grant to HYVE a worldwide, irrevocable, perpetual, non-exclusive,
          transferable, royalty-free license, sublicensable and transferable
          right, to use, view, copy, adapt, modify, distribute, license, sell,
          transfer, publicly display, publicly perform, transit, stream,
          broadcast and otherwise exploit such User Content including but
          without limitation through or by means of the Website, Mobile
          Application and/or Network. In connection, you hereby renounce and
          waive in favour of HYVE any moral rights you have or might have, now
          or in the future, with respect to User Content. Nothing in these Terms
          will be deemed to restrict any rights that you have may to use and
          exploit any User Content. You also hereby represent and warrant that
          you have the right to grant us the right over your User Content and
          that you will indemnify us for any loss resulting from a breach of
          this warranty and defend us against claims resulting from the same.
          You acknowledge and agree that you are solely responsible for all User
          Content that you make available through the Website, Mobile
          Application and/or Network. Accordingly, you represent and warrant
          that you either are the sole and exclusive owner of all User Content
          that you make available through the Website, Mobile Application and/or
          Network or you have all rights, licenses, consents and releases that
          are necessary to grant to HYVE the rights in such User Content, as
          contemplated under this agreement and neither the User Content nor
          your posting, uploading, publication, submission or transmittal of the
          User Content or HYVE’s use of the User Content or any portion
          therefore will infringe, misappropriate or violate a third-party
          patent, copyright, trademark, trade secret, moral rights or other
          intellectual property rights or rights of publicity or privacy or
          result in the violation of any applicable law or regulation.
        </p>
        <h4>12. INTELLECTUAL PROPERTY</h4>
        <p>
          References in these conditions to Intellectual Property rights, the
          (“IP”) means copyright, patents, rights in inventions, rights in
          confidential information, know-how, trade secrets, trademarks, service
          marks, trade names, design rights, rights in get-up, database rights,
          rights in data, domain names, rights in computer software (including
          source code and object code) and all similar rights of whatever nature
          and in each case, whether registered or not, including any
          applications to protect or register such rights, including all
          renewals and extensions of such rights or application, whether vested,
          contingent or future and wherever in the world they exist. Unless
          specifically indicated otherwise, the Website, Mobile Application
          and/or Network together with all IP rights are owned by HYVE, our
          licensors or both (as applicable). Such IP rights are protected by
          copyright laws and treaties around the world. We and our licensors
          reserve all of our and their rights in any such IP rights in
          connection with these conditions. This means that we and they remain
          owners of them and free to use them as we and they see fit. The
          Website, Mobile Application and/or Network are for intended uses only
          and nothing in these conditions grant you any legal rights to access
          or use the Website, Mobile Application and/or Network for any other
          purposes. You may not use the Website, Mobile Application and/or
          Network for any further or additional uses and in particular may not
          reproduce or otherwise make available the same in whole or in part,
          without the prior written consent of HYVE or our licensors, if
          applicable. In addition, none of the content or design on the Website,
          Mobile Application and Network may be copied, altered in any way or
          transmitted or distributed to any other party without our prior
          express written permission. You retain ownership of any information or
          data you provide to or through the Site (“User Data”). Subject to
          foregoing, by using the Website, Mobile Application and/or Network,
          you are granting HYVE a non-exclusive, perpetual, irrevocable, royalty
          free, transferable license to copy, reproduce, remove, process, adapt,
          transmit, save, host, display and otherwise use your User Data in
          accordance with our Privacy Policy which can be found in the headline
          of these Terms and in Clause 26. We reserve the right to remove any
          User Data that in our sole discretion deem inappropriate, abusive,
          unlawful or otherwise contrary to, or in breach of, these Terms or the
          proper use of the Website, Mobile Application and/or Network
          (“Infringing User Data”). HYVE shall not be responsible or liable to
          any third-party in respect of any Infringing User Data.
        </p>
        <h4>13. APPLICATION LICENSE</h4>
        <p>
          Subject to your compliance with these Terms, HYVE grants you a
          limited, non-exclusive, non-sublicensable, revocable, non-transferable
          license to: (i) access and use the Mobile Application on your personal
          device solely in connection with your use of HYVE; and (ii) access and
          use any content, information and related materials that may be made
          available through HYVE, in each case solely for your personal,
          non-commercial use. Any rights not expressly granted herein are
          reserved by HYVE and HYVE’s licensors.
        </p>
        <h4>14. SMS MESSAGING</h4>
        <p>
          You agree that HYVE may contact you by telephone or text messages
          (including by an automatic telephone dialling system) at any of the
          phone numbers provided by you or on your behalf in connection with an
          Account, including for marketing purposes. You understand that you are
          not required to provide this consent as a condition of purchasing
          anything from the Website, Mobile Application or Network. You also
          understand that you may opt out of receiving text messages from HYVE
          at any time. If you do not choose to opt out, HYVE may contact you as
          outlined in our Privacy Policy which can be found in the headline of
          these Terms and in Clause 26.{' '}
        </p>
        <h4>15. THIRD-PARTY SERVICES</h4>
        <p>
          During use of the Website, Mobile Application and Network, you may
          enter into correspondence with, purchase good and/or services from, or
          participate in promotions of third-party service providers,
          advertisers, sponsors or affiliates showing their goods and/or
          services through the Website, Mobile Application or Network. Any such
          activity and any Terms, conditions, warranties or representations
          associated with such activity is solely between you and the applicable
          third-party. HYVE and its licensors shall have no liability,
          obligation or responsibility for any such correspondence, purchase,
          transaction, services or promotion between you and any such
          third-party. HYVE does not endorse any sites on the internet that are
          linked through the Website, Mobile Application or Network and in no
          event shall HYVE or its licensors be responsible for any content,
          products, services or other materials on or available from such sites
          or third-party providers. HYVE provides the Website, Mobile
          Application and Network to you pursuant to the Terms of this
          agreement. You recognise that certain third-party providers of goods
          and/or services may require your agreement to additional or different
          Terms prior to your use of or access to such goods or services and
          HYVE disclaims any and all responsibility or liability arising from
          such agreements between you and third-party providers.
        </p>
        <p>
          HYVE may rely on third-party advertising and marketing supplied
          through the Website, Mobile Application and Network and any other
          mechanisms to subsidise the Website, Mobile Application or Network. By
          agreeing to these Terms, you agree to receive such advertising and
          marketing. If you do not want to receive such advertising, you must
          notify us at hi@trainhyve.com. HYVE reserves the right to charge you a
          higher fee for the Website, Mobile Application and Network should you
          choose not to receive this advertising services. HYVE may compile,
          release and disclose non-identifiable information regarding you and
          your use of the Website, Mobile Application and Network as part of a
          customer profile or similar report or analysis including but without
          limitation to third-party service providers. You agree that it is your
          responsibility to take reasonable precautions in all actions and
          interactions with any third-party you interact with through HYVE.
        </p>
        <h4>16. INDEMNITY</h4>
        <p>
          You agree to release, defend, indemnify and hold harmless HYVE, our
          Landlords and/or our Managing Agents and their respectable officers,
          directors, agents, subsidiaries, joint ventures, employees and
          third-party service providers, from all claims, demands, losses,
          liabilities, costs, expenses, obligations and damages of every kind
          and nature, known and unknown, including reasonable legal fees arising
          out of your use and access to the Website, Mobile Application and
          Network, your violation of any term of these Terms, your violation of
          any law or the rights of a third-party including without limitation,
          any copyright, property or privacy right, or any claim that any
          content you submitted caused damage to a third-party. You agree to
          notify HYVE and in writing at hi@trainhyve.com of such claim. If a
          claim subject to IP infringement indemnification is made, HYVE shall
          have the right to obtain for you the right to continue using HYVE or
          replace and/or modify HYVE so that it becomes non-infringing and still
          provide substantially the same functionality for the user. The
          indemnification obligated will survive the terminate of these Terms
          and your use of the Website, Mobile Application and Network.
        </p>
        <h4>17. DISCLAIMER</h4>
        <p>
          HYVE provides the Website, Mobile Application and Network along with
          its content, materials, information, software and products included
          therein including separate services performed by third parties under
          control for use on an “as if”, “where is” and “as available” basis. To
          the maximum extent permitted by law, HYVE disclaims all
          representations and warranties whether express or implied with respect
          to HYVE including without limitation; any warranties or
          merchantability and fitness for a particular purpose, features,
          quality, non-infringement, title, performance, compatibility, security
          or accuracy and that the quality of the HYVE as well as any products,
          service, information or other material purchased or obtained by you
          through the Website, Mobile Application or Network will meet your
          requirements or expectations. You acknowledge and agree that the
          entire risk arising out of your use of HYVE and/or third-party
          services or products remains solely with you to the maximum extent
          permitted by law. You hereby acknowledge and agree that; the locking
          mechanism used on the HYVE is provided by a third-party and HYVE, the
          Landlord and/or the Managing Agent have no liability whatsoever with
          respect to any failure of the locking system to work in its intended
          manner including without limitation any loss or theft of your property
          as the Network is located in buildings which are not owned, managed or
          manned by HYVE and HYVE makes no representations or warranties
          relating thereto. Additionally, HYVE, the Landlord and/or the Managing
          Agent make no representation or warranty with respect to the
          suitability of the Network for any particular activity and shall not
          be liable in anyway for such activities. HYVE, the Landlord and/or the
          Managing Agent does not warrant that the HYVE will operate in an
          uninterrupted or error-free manner or that the HYVE will always be
          available or free from all harmful components or that it is safe,
          secured from unauthorised access, immune from damages, free of
          malfunctions, bugs or failures, including, but not limited to hardware
          failures, originating either from HYVE or its providers.
        </p>
        <h4>18. WAIVER</h4>
        <p>
          I declare that I intend to use some or all of the activities,
          facilities and services offered by HYVE and I understand that each
          person (including myself), has different skill levels for
          participating in such activity. I assume full responsibility during
          and after my participation at HYVE and for my choices to use or apply,
          at my own risk, any portion of the equipment, facilities, information
          or instruction available. I understand that part of the risk involved
          in undertaking exercise is relative to my own state of fitness or
          health (physical and emotionally) and the awareness, care and skill in
          which I conduct myself in any usage of HYVE. I agree that to the best
          of my knowledge and belief I am in good health and not knowingly
          incapable of engaging in either active or passive exercise and that
          such exercise would not be detrimental to my health, safety, comfort,
          wellbeing or physical condition. In addition, I understand that I
          should immediately withdraw from, reduce or modify my involvement in
          any of the activities and I should do so on recognition of any signs
          of physical discomfort which may include: lightheadedness, fainting,
          chest discomfort, leg cramps, nausea, etc. I further understand that
          there are possible risks involved in participating in exercise and
          HYVE will accept no responsibility for my actions, injuries or health
          during my use of HYVE. If you start to feel unwell or a life
          threatening emergency occurs in a HYVE, you should immediately call
          the Emergency Services by dialling 999 (or the relevant country code)
          on your phone.
        </p>

        <h4>19. LIMITATION OF LIABILITY</h4>
        <p>
          HYVE, it’s Officers, Directors, Shareholders, Employees, Suppliers,
          Sub-Contractors, Agents, Landlords and Managing Agents will not be
          liable for any direct, indirect, incidental, consequential or any
          other damage or loss including loss of profit and loss of data, costs,
          expenses and payments, arising from or in connection with the use or
          inability to use the services of HYVE. This includes any fault or
          error made by our employees or anyone acting on our behalf or from
          your reliance on the content of the services or from any denial or
          cancellation of your Account or from the retention, deletion, disclose
          and any other use or loss of your content on the services. In no event
          shall HYVE, its licensors’ or any Landlord or Managing Agent aggregate
          liability arising out of or relating to this agreement of your use of
          or inability to use the Website, Mobile Application or Network. You
          understand and agree that you obtain or otherwise download content,
          material or data through or for the use of HYVE at your own discretion
          and risk and that you will be solely responsible for any damages to
          your computer system or loss of data that results from the download of
          such material or data. In addition, HYVE does not represent or warrant
          that the information accessible via the Website, Mobile Application or
          Network is accurate, complete or up to date. Responsibility for the
          decisions you make regarding HYVE offered via the Website, Mobile
          Application or Network with all its implications rest solely with you.
          HYVE will not assess the suitability, legality or ability of any such
          building owners or other third parties and you expressly waive and
          release HYVE and building owners, to the maximum extent, allowable
          under applicable law, from any and all liability, claims, causes of
          action or damages arising from your use of the Website, Mobile
          Application or Network and/or in any way related to the third parties
          introduced to you by HYVE. The quality of the building in which HYVE
          are located are entirely the responsibility of the Landlord and/or
          Managing Agent. You understand that by using HYVE you may be exposed
          to locations that are potentially dangerous, offensive, harmful to
          minors, unsafe or otherwise objectionable and that you use HYVE at
          your own risk. To the extend applicable under local law, nothing in
          this agreement shall limit the liability for death or personal injury
          caused by negligence from HYVE or for fraud or fraudulent
          misrepresentation.
        </p>

        <h4>20. RATING SYSTEM</h4>
        <p>
          You may be required to rate certain aspects of your overall experience
          with HYVE as well as certain aspects e.g. other users. This rating
          will be prompted through the Website and/or Mobile Application. If you
          do not participate when requested, HYVE reserves the right (without
          limiting any of HYVE’s other rights hereunder) to restrict your access
          to HYVE. Additionally, if HYVE or if other users, rate your usage of
          HYVE as being below a threshold acceptable to HYVE, then we may in our
          sole discretion, and without limiting any of HYVE’s other rights
          hereunder, restrict your access to HYVE.{' '}
        </p>
        <h4>21. PROMOTION TERMS</h4>
        <p>
          HYVE reserves the right to end any promotion without warning at any
          time. Any prizes offered by HYVE must be claimed within 21 days of
          announcement of the winners. HYVE have 45 days to issue any prize. All
          HYVE challenges are subject to fair play. HYVE reserve the right to
          redeem any prize in the form of a voucher or pre-purchased credit.
        </p>
        <h4>22. NOTICES</h4>
        <p>
          HYVE may send you notices by means of email to your email address on
          record in your Account. You may give notice, and address any complaint
          or claim to HYVE (such notice, complaint or claim shall be deemed
          given when received by HYVE) at any time by means of email to
          hi@trainhyve.com.
        </p>
        <h4>23. GOVERNING LAW</h4>
        <p>
          These conditions, their subject matter and formation, are governed by
          the laws of England and Wales. You and we both agree that the courts
          of England and Wales will have exclusion jurisdiction. However, if you
          are a resident in Northern Ireland, you may also bring proceedings in
          Northern Ireland and if you are a resident of Scotland, you may also
          bring proceedings in Scotland.{' '}
        </p>
        <h4>24. TERMINATION</h4>
        <p>
          You agree that HYVE, in its sole discretion and for any or no reason,
          may terminate any Account (or any part thereof) you may have with HYVE
          or your use of HYVE, and remove and discard all or any part of your
          Account or any of your User Content, at any time. HYVE may also in its
          sole discretion and at any time discontinue providing access to the
          Website, Mobile Application and Network or any part thereof, with or
          without notice. You agree that any termination of your access to the
          Website, Mobile Application or Network or any Account you may have or
          portion thereof may be effected without prior notice, and you agree
          that HYVE shall not be liable to you. These remedies are in addition
          to any other remedies HYVE may have at law or in equity. You may
          terminate your Account at any time by ceasing all use of the Website,
          deleting the Mobile Application from your device and no longer
          partaking in any sessions in the Network. Furthermore, you may request
          HYVE to cancel your Account via email sent to hi@trainhyve.com.
        </p>
        <h4>25. GENERAL</h4>
        <h4>
          1. Welcome to HYVE’s Privacy and Data Protection Policy (“Privacy
          Policy”).{' '}
        </h4>
        <p>
          At HYVE (“HYVE”, “we”, “us” or “our”) we are committed to protecting
          and respecting your privacy and Personal Data in compliance with the
          law and guidelines of the EU General Data Protection Regulation
          (“GDPR”). This Privacy Policy explains how we collect, process and
          keep your data safe. The Privacy Policy will tell you about your
          privacy rights, how the law protects you, and inform our employees and
          staff members of all their obligations and protocols when processing
          data. The individuals from which we may gather and use data can
          include: • Customers • Suppliers • Business contacts • Suppliers •
          Business contacts • Customers • Third parties connected to customers
          and any other people that the organisation has a relationship with or
          may need to contact. This Privacy Policy applies to all our employees
          and staff members and all Personal Data processed at any time by us.
          1.2 Who is your Data Controller HYVE is your Data Controller and
          responsible for your Personal Data. We are not obliged by the GDPR to
          appoint a Data Protection Officer and have not voluntarily appointed
          one at this time. Therefore, any enquiries about your data should be
          sent to us on email at hi@trainhyve.com. You have the right to make a
          complaint at any time to the Information Commissioner’s Office (ICO),
          the UK’s supervisory authority for data protection issues
          (www.ico.org.uk). We would, however, appreciate the chance to deal
          with your concerns before you approach the ICO so please contact us in
          the first instance.
        </p>
        <h4>2. INFORMATION WE HOLD </h4>
        <p>
          2.1 When you use the Website and/or Mobile Application we may collect
          the following information about you: <br />
          2.2 Personal information including first and last name, date of birth
          and photograph; <br />
          2.3 Contact information including residential address, geographic
          location, primary email address and/or primary phone number; <br />
          2.4 If you sign up to the Website and/or Mobile Application through a
          third-party service such as Facebook or Google, we may extract
          information from your Account e.g. name, email address, photograph and
          any other personal information that your privacy settings permit us to
          access; <br />
          2.5 Information about your contacts when you invite others to join
          your workout session or as apart of any promotional activities we run;{' '}
          <br />
          2.6 Technical information including IP address, operating system,
          browser type and related information regarding the device you used to
          visit the site, the length of your visit and your interactions with
          the site; <br />
          2.7 Information relevant to service, customer surveys and/or offers;{' '}
          <br />
          2.8 We may monitor your use of the Website and Mobile Application
          through ‘cookies’ or similar tracking technologies (Facebook Pixel and
          Google Analytics). We may also monitor traffic, location or other data
          and information about users of the Website and Mobile Application.
          Such data and information, to the extent that you are individually
          identifiable from it, shall constitute information as defined above.
          However, some of this data will be aggregated or statistical, which
          means that we will not be able to identify you individually; <br />
          2.9 Occasionally we may receive information about you from other
          sources e.g. from other users on the Website and/or Mobile Application
          who provide feedback ratings or other details. We will add this
          information to the data we already have collected to help us carry out
          activities listed.
        </p>
        <h4>3. HOW LONG WE HOLD YOUR INFORMATION </h4>
        <p>
          3.1 We will keep your information only for as long as we need to hold
          it for the purposes set out below in Clause 6.
          <br />
          3.2 If required, we will be entitled to hold information for longer
          periods in order to comply with our legal or regulatory obligations.
        </p>
        <h4>4. LEGAL BASIS FOR YOUR INFORMATION </h4>
        <p>
          4.1 Under Data Protection Law, we may only process your information if
          we have a “legal basis” e.g. a legally permitted reason for doing so.
          For the purposes of this policy, our legal basis for processing your
          information is: <br />
          4.1.1 Where we have requested it and made it known to you or asked for
          your consent set out in Clause 5; <br />
          4.1.2 Where the processing is necessary e.g. taking any preliminary
          steps that are required before you can enter a contract with us as set
          out in this policy and others); <br />
          4.1.3 Where there is legitimate business interest of providing
          services to our users through the Website and/or Mobile Application
          which requires the processing of your information to enable us to
          provide these services. Subject to your rights set out in Clause 14.
        </p>
        <h4>5. CONSENT TO PROCESS </h4>
        <p>
          5.1 You will be required to give consent to certain processing
          activities before we can process your information. Where applicable,
          we will seek this consent from you when you first submit information
          through the Website and/or Mobile Application. <br />
          5.2 If you have previously given consent you may freely withdraw such
          consent at any point by emailing hi@trainhyve.com. <br />
          5.3 If you withdraw your consent and if we do not have another legal
          basis for processing your information as set out in Clause 4, then we
          will stop processing your information. If we do have another legal
          basis for processing your information, then we may continue to do so
          subject to your legal rights in Clause 14. <br />
          5.4 Please note that if we need to process your information in order
          to operate the Website or and/or Mobile Application, and you object or
          do not consent to us processing your information, the Website and/or
          the Mobile Application will not be available to you.
        </p>
        <h4>6. HOW WE USE YOUR INFORMATION </h4>
        <p>
          6.1 We may process information held about you for the following
          purposes: <br /> 6.1.1 To operate, administer, maintain, provide,
          analyse and improve the Website and/or Mobile Application along with
          the services available through them; <br />
          6.1.2 To investigate and address any comments, enquiries or complaints
          made by you regarding the Website and/or Mobile Application and any
          similar or related comments enquiries or complaints from other users;{' '}
          <br />
          6.1.3 To ensure the content on the Website and/or Mobile Application
          is presented in the most effective manner for you and for your device;{' '}
          <br />
          6.1.4 To conduct research, statistical analysis and behavioural
          analysis which includes anonymising data for these purposes; <br />
          6.1.5 To provide insights based on aggregated, anonymous data
          collected through the research and analysis referred to at 6.1.4
          above; <br />
          6.1.6 To allow you to participate in interactive features of the
          Website and/or Mobile Application including inputting information and
          providing feedback; <br />
          6.1.7 To contact you for marketing purposes, where applicable subject
          to Clause 7; <br />
          6.1.8 To disclose your information to selected third parties as
          permitted by this policy subject to Clause 8; <br />
          6.1.9 To notify you about the changes to the Website and/or Mobile
          Application; <br />
          6.1.10 To comply with our legal obligations, including related to the
          protection of Personal Data.
        </p>
        <h4>7. MARKETING AND OPTING OUT </h4>
        <p>
          7.1 If you have given us permission, we may contact you through
          electronic messaging services including but not limited to; email, SMS
          and similar (“Messaging Services”) about our products, services,
          promotions and special offers that may be of interest to you. We will
          inform you before collecting your data and always seek your permission
          if we intend to use your data for such purposes. If you prefer not to
          receive any direct marketing communication from us, or you no longer
          wish to receive them, you can withhold consent and/or opt out anytime
          using the methods outlined in 7.4. <br />
          7.2 If you have given us permission, we may contact you through these
          (“Messaging Services”) to provide information about products,
          services, promotions, special offers and other information we think
          may be of interest to you from carefully selected third parties. We
          will inform you before collecting your data if we intend to use your
          data for such purposes. If you would rather not receive such
          third-party marketing information from us, you can withhold consent
          and/or opt out at any time using methods outlined in 7.4. <br />
          7.3 If you have given us permission, we may share your personal data
          with carefully selected third party organisations and/or business
          partners so that they can contact you directly through (“Messaging
          Services”) about products, services, promotions and special offers
          that might be of interest to you. We will inform you before collecting
          your data and seek your permission if we intend to disclose your data
          to third parties for such purposes. If you would rather not receive
          direct marketing communications from our third-party organisation
          and/or business partners, you can withhold consent and/or opt out at
          any time using methods outlined in 7.4. <br />
          7.4 You have the right at anytime to ask us or any third-party, to
          stop processing your information for direct marketing purposes. If you
          wish to exercise this right, you should contact us by sending an email
          to hi@trainhyve.com or contact the relevant third-party using their
          contact details. Please ensure you provide the information of the
          (“Messaging Services”) you are receiving marketing on so you can be
          identified and removed. Alternatively, you can follow the change of
          preferences instructions in the footer of emails or other
          communications you receive from us or them.
        </p>
        <h4>8. DISCLOSURE OF YOUR INFORMATION </h4>
        <p>
          8.1 We may disclose your information including Personal Data: <br />
          8.1.1 To other companies within our group of companies including
          subsidiaries of our ultimate holding company as defined in section
          1159 of the UK Companies Act 2006; <br />
          8.1.2 To third-party organisations, business partners and/or service
          providers to enable them to undertake services for us and/or on our
          behalf. We will always ensure we have appropriate measures in place to
          protect your Personal Data; <br />
          8.1.3 If we are under a duty to disclose or share Personal Data in
          order to comply with any legal obligation including but not limited to
          any request or order from law enforcement agencies and/or HMRC in
          connection with any investigation to help prevent unlawful activity;{' '}
          <br />
          8.1.4 To other third parties if you have consented us to do so; <br />
          8.1.5 We may disclose aggregated, anonymous information which you
          cannot personally be identified by or insights based on such anonymous
          information to selected third parties including but not limited to
          analytics and search engine providers to assist us in the improvement
          and optimisation of the Website and/or Mobile Application; <br />
          8.1.6 If our whole business is sold or integrated with another
          business your information may be disclosed to our advisers and any
          prospective purchasers and their advisers and will be passed on to the
          new owners of the business.
        </p>
        <h4>9. KEEPING INFORMATION SECURE </h4>
        <p>
          9.1 We will use technical and organisational measures in accordance
          with good industry practise to safeguard your information including
          the use of data encryption wherever possible. <br />
          9.2 While we will use all the reasonable efforts to safeguard your
          information, you acknowledge that the use of the internet is not
          entirely secure and for this reason we cannot guarantee the security
          or integrity of any information that is transferred from you or to you
          via the internet.
          <h4>10. MONITORING </h4>
          <p>
            10.1 We may monitor and record communications with you such as
            telephone conversation and emails for the purposes of provision of
            services, quality assurance, training, fraud prevention and
            compliance purposes. Any information that we receive through such
            monitoring and communications will be added to the information we
            already hold about you and may also be used for the purposes listed
            in Clause 6.
          </p>
          <h4>11. OVERSEAS TRANSFERS </h4>
          <p>
            11.1 Every so often we may need to transfer your information to
            countries outside the European Economic Area, which compromises the
            EU members plus Norway, Iceland and Liechtenstein (“EEA”). Non-EEA
            countries that we may need to transfer your information to include
            but not limited to the United States of America and India. <br />
            11.2 Such countries may not have similar protections in place
            regarding the protection and use of your data as those set out in
            this policy. Therefore, if we do transfer your information to
            countries outside the EEA, we will take reasonable steps in
            accordance with Data Protection Law to ensure adequate protections
            are in place to ensure the security of your information. <br />
            11.3 By submitting your information to us in accordance with this
            policy, you also consent to these transfers for the purposes
            specified inside this policy.
          </p>
        </p>
        <h4>12. PAYMENT PROCESSING </h4>
        <p>
          12.1 Payment details you provide will encrypted using secure sockets
          layer (SSL) technology before they are submitted over the internet.
          Payments made on the Website and/or Mobile Application are made
          through our payment gateway provider, Stripe. You will be providing
          debit or credit card information directly to Stripe which operates a
          secure server to process payment details, encrypting your information
          and authorising the payment. Information which you supply to Stripe is
          not within our control and is subject to Stripe’s own Privacy Policy
          and Terms.
        </p>
        <h4>13. INVITEES AND OTHER INDIVIDUALS </h4>
        <p>
          13.1 As part of our service, we allow registered users to send
          invitations to individuals (“Invitees”) to invite them to participate
          in a session. Where you wish to send an invitation to a propose
          individual who is also a registered user on the Website and/or Mobile
          Application, we will process Personal Data that relates to that
          individual in accordance with this policy. <br />
          13.2 Where you wish to send an invitation to a proposed individual who
          is not a registered user of the Website and/or Mobile Application, we
          will not collect or request any information from you any information
          from which that individual can be personally identified. After sending
          an invitation link and if clicked, the individual will be redirected
          to the Mobile Application. We will seek consent from them to process
          their Personal Data in accordance with these Terms. <br />
          13.3 Subject to the above, if you otherwise give us Personal Data that
          relates to a third-party, you confirm that the third-party has
          appointed you to act on his/her/their behalf and has agreed that you
          can give consent on his/her/their behalf to the processing of
          his/her/their Personal Data, receive on his/her/their behalf any data
          protection notices and give consent to the transfer of his/her/their
          Personal Data abroad, where applicable.
        </p>
        <h4>14. YOUR RIGHTS </h4>
        <p>
          14.1 You have the right to request information about the Personal Data
          that we may hold and/or process about you, including whether or not we
          are holding and/or processing your Personal Data, the extent of the
          Personal Data we are holding and the purposes and extent of the
          processing. <br />
          14.2 You have the right to request that any inaccurate information we
          hold about you to be updated and/or corrected. If you require any
          changes to your information or you become aware of any inaccuracies in
          such information, please let us know by forwarding your request to
          hi@trainhyve.com. <br />
          14.3 From 25th May 2018, you have the right in certain circumstances
          to request that we delete all Personal Data we hold about you. (“Right
          of Erasure”). Please note that this right is not available in all
          circumstances e.g. where we need to retain the Personal Data for legal
          compliance purposes. If this is the case, we will let you know. <br />
          14.4 From 25th May 2018, you have the right in certain circumstances
          to request that we restrict the processing of your Personal Data e.g.
          where the Personal Data is inaccurate or where you have objected to
          the processing. See Clause 14.6. <br />
          14.5 From 25th May 2018, you have the right in certain circumstances
          to request a copy of the Personal Data we hold about you and to have
          it provided in a structured format suitable for you to be able to
          transfer it to a different Controller (“Right To Data Portability”).
          Please note this right is only available in some circumstances e.g.
          where the processing is carried out by automated means. If you
          requested the right and it is not available, we will let you know.{' '}
          <br />
          14.6 You have the right in certain circumstances to object to the
          processing of your Personal Data. If so, we shall stop processing your
          Personal Data unless we can demonstrate sufficient and compelling
          legitimate grounds for continuing the processing which override your
          own interests. If, as a result of your circumstances, you do not have
          the right to object to such processing, we will let you know. <br />
          14.7 You have the right in certain circumstances not to be a subject
          to a decision based solely on automated processing e.g. where a
          computer algorithm, rather than a person, makes decisions which affect
          your contractual rights. Please note that this right is not available
          in all circumstances. If you request this right and it is not
          available to you, we will let you know. <br />
          14.8 You have the right to object to direct marketing. Please see
          Clause 7.4.
        </p>

        <h4>15. COMPLAINTS </h4>
        <p>
          15.1 If you have any concerns about how we collect or process your
          information then you have the right to lodge a complaint with a
          supervisory authority, which for the UK is the UK Information
          Commissioner’s Office (“ICO”). Complaints can be submitted to the ICO
          through the ICO helpline by calling 0303 123 1113. Further information
          about reporting concerns to the ICO is available at
          https://ico.org.uk/concerns/.{' '}
        </p>
        <h4>16. COOKIES AND RELATED SOFTWARE </h4>
        <p>
          16.1 Our software may issue (“Cookies”) which are small text files on
          your device. When you access and use the Website, you will be asked to
          consent to this the first time you visit our Website or each time
          after your cache has been cleared. Cookies do not affect your privacy
          or security as a Cookie cannot read data from your system or other
          Cookie files created by other websites. <br />
          16.2 Our Website uses Cookies and other tracking software to
          distinguish our users, collect standard internet log information and
          to collect visitor behaviour information. This information is used to
          track user interactions with the Website and allows us to provide you
          with a personalised and improved experience along with improving our
          site and build statistical reports. We may also use Cookies to allow
          us to effectively continue marketing to you after visiting our
          Website. <br />
          16.3 You can set your system not to accept cookies, if you wish. You
          can do this by changing your browser settings so that cookies are not
          accepted. However, please note, that some of our Website features may
          not function if you remove cookies from your system. For further
          general information about cookies, please visit
          https://www.aboutcookies.org or https://www.allaboutcookies.org.
        </p>
        <h4>17. CHANGES TO THE POLICY </h4>
        <p>
          17.1 We keep this policy under regular review and may change it. If we
          change the policy we will post the changes on this page and place
          notices on other pages of the Website and/or mobile application as
          applicable so that you can be aware of the information we collect and
          how we use it at all times. You are responsible for ensuring that you
          are aware of the most recent version of this policy as it will apply
          each time you access the Website and/or mobile application. <br />
          17.2 This policy was last updated on 14th October 2021.
        </p>
        <h4>18. LINKS TO OTHER WEBSITES </h4>
        <p>
          18.1 Our site may contain links to other websites. This policy only
          applies to our site. If you access links to other websites, any
          information you provide to them will be subject to the privacy
          policies of those other websites.
        </p>
        <h4>19. ACCESSIBILITY </h4>
        <p>
          19.1 This policy aims to provide you with all the relevant details
          about how we process your information in a concise, transparent,
          intelligible and easily accessible form, using clear and plain
          language. If you have any difficulty in reading or understanding this
          policy, or if you would like this policy in another format e.g. audio,
          large print or braille, please let us know.
        </p>
        <h4>20. CONTACT US </h4>
        <p>
          20.1 We welcome your feedback and questions on this policy. If you
          wish to contact us as the Data Controller, please email us on
          hi@trainhyve.com.{' '}
        </p>
        <h4>21. CCTV POLICY </h4>
        <p>
          21.1 This section sets out the appropriate actions and procedures
          which HYVE follows in respect of the use of CCTV (closed circuit
          television) surveillance systems (“CCTV Systems”) at our premises.
          <br />
          21.1.1 Sound recording is disabled on all cameras. <br />
          21.1.2 Please note that all our Network are monitored by CCTV 24 hours
          a day. HYVE reserves the right for its employees and contractors to
          review footage as required and by entering onto our sites you consent
          to your image being recorded and reviewed and waive any and all claims
          in relation to same. Recorded CCTV footage will be stored securely and
          retained in compliance with applicable laws. <br />
          21.2 In drawing up this policy, due Account has been taken of the
          following: <br />
          21.2.1 The Regulation and any other relevant Data Protection
          legislation; <br />
          21.2.2 The CCTV Code of Practice produced by the Information
          Commissioner (“Code of Practice”); <br />
          21.2.3 The Human Rights Act 1998. <br />
          21.3 This policy will cover all employees and persons providing a
          service to HYVE, visitors and all other persons whose image(s) may be
          captured by our CCTV Systems. <br />
          21.4 We will also ensure that the personal data captured by our CCTV
          Systems is only processed in accordance with the following
          requirements: <br />
          21.4.1 It will be processed fairly, lawfully and in a transparent
          manner; <br />
          21.4.2 It will only be collected for specified, explicit and
          legitimate purposes and not further processed in any manner
          incompatible with those purposes; <br />
          21.4.3 It will be adequate, relevant and limited to what is necessary
          in relation to the purposes for which they are processed; <br />
          21.4.4 It will be accurate and, where necessary, kept up to date;{' '}
          <br />
          21.4.5 It will not be kept for longer than is necessary for the
          purposes for which the personal data are processed; 21.4.6 It will be
          processed in a manner that ensures appropriate security of the
          personal data. <br />
          21.5 The HYVE CCTV Officer has the legal responsibility for the
          day-to-day compliance with the requirements of this policy.
          <br />
          21.6 The purpose of the use of the CCTV Systems and the collection and
          processing of CCTV images is for the prevention or detection of crime
          or disorder, apprehension and prosecution of offenders (including use
          of images as evidence in criminal proceedings), interest of public and
          employee Health and Safety, protection of public health and the
          protection of our property and assets and to ensure compliance with
          our policies and procedures. <br />
          21.7 Prior to any camera installation the CCTV Officer will ensure
          that the installation complies with this policy and that the use of
          any camera is justified, necessary and proportionate. The CCTV Officer
          will regularly assess whether the use of any camera and the CCTV
          System as a whole continues to be justified, necessary and
          proportionate. <br />
          21.8 The location of the equipment is carefully considered because the
          way in which images are captured needs to comply with the Regulation.{' '}
          <br />
          21.9 All cameras are located in prominent positions within public and
          staff view and do not infringe on sensitive areas. All CCTV
          surveillance is automatically recorded, and any breach of this siting
          policy will be detected via controlled access to the CCTV System and
          auditing of the CCTV System.
          <br />
          21.10 The images produced by the equipment will as far as possible be
          of a quality that is effective for the purpose(s) for which they are
          intended. Upon installation, all equipment is tested to ensure that
          only the designated areas are monitored, and suitable quality pictures
          are available in live and play back mode. All CCTV equipment is
          maintained under contract. <br />
          21.11 Images which are not required for the purpose(s) for which the
          equipment is being used will not be retained for longer than is
          necessary. While images are retained, it is essential that their
          integrity be maintained, whether it is to ensure their evidential
          value or to protect the rights of people whose images may have been
          recorded. Access to and security of the images is controlled in
          accordance with the requirements of the Regulation.
          <br />
          21.12 All images are digitally recorded and stored securely within the
          system’s hard drives. Images are stored for a minimum of 14 days and
          typical for no more than 31 days. <br />
          21.13 Where the images are required for any other purpose, for example
          system testing, evidential purposes or disciplinary proceedings, a
          copy file will be moved to an access controlled confidential location
          and held until completion of the investigation. Viewing of images
          within the system is controlled by the CCTV Officer or a person
          nominated to act on their behalf. Only persons trained in the use of
          the equipment and authorised by the CCTV Officer can access data.{' '}
          <br />
          21.14 Access to, and disclosure of, the images recorded by our CCTV
          System and similar surveillance equipment is restricted and carefully
          controlled. This ensures that the rights of individuals are preserved,
          and the continuity of evidence remains intact should the images be
          required for evidential purposes e.g. a police enquiry or an
          investigation being undertaken as part of an internal procedure.{' '}
          <br />
          21.15 Access to the medium on which the images are displayed and
          recorded is restricted to the CCTV Officer, staff authorised by them
          and third parties as authorised from time to time for specific
          purposes. Access to and disclosure of images is permitted only if it
          supports the purpose for which such images were collected.
          <br />
          21.16 The Regulation gives any individual the right to request access
          to CCTV images which contain their personal data. <br />
          21.17 Individuals who request access to images must do so by
          contacting us on hi@trainhyve.com. Please send details of; who you
          are, the reason for request, your contact details, the location of
          CCTV, the time in question and any details that might be useful. Upon
          receipt of the request, the CCTV Officer, or another member of staff
          authorised by them, will determine whether disclosure is appropriate
          and whether there is a duty of care to protect the images of any third
          parties. If the duty of care cannot be discharged, then the request
          can be refused. <br />A written response will be made to the
          individual, giving the decision (and if the request has been refused,
          giving reasons) within 31 days of receipt of the request.
        </p>
      </div>
    </div>
  )
}

export default Terms
